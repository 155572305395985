import React from 'react'
import Layout from '../components/Layout'
import { navigate } from 'gatsby'

const SkickatPage = () => {
	return (
		<Layout>
			<div className="bg-green-bg w-full h-full flex items-center justify-center min-h-screenWithoutNavAndFooter flex-col">
        <h3 className="text-lg leading-tight font-bold mb-4">Tack, din nominering har skickats!</h3>
        <button
          onClick={() => navigate(-1)}
          className="bg-green-accent hover:bg-green-accent-dark text-white rounded-lg w-56 px-6 py-3 outline-none focus:outline-none text-center ease-in duration-300"
        >
          Tillbaka
        </button>
			</div>
		</Layout>
	)
}

export default SkickatPage
